import React, { useEffect } from 'react';
import Layout from '../../../../components/generator/Layout'
import {useNavigate} from '@gatsbyjs/reach-router';

import {Main}  from '../../../../components/Main';
import {
    Title, Text,
    Input,
    Button,
    Group,
    MediaQuery,
    Burger
} from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';
import { setWords, setWordSearchConfig, setHiddenWords, resetWords } from '../../../../state/wordSearch';
import { uiSelector } from '../../../../state/ui';

const Sheets=({ params }: {params: any})=>{
    const navigate = useNavigate();
    const {googleId} = useSelector(uiSelector);

    const {title} = params
    // const googleId = `13xXnQrnT4vgg-RaXK_4ippKoxLD9WR2hdnIW3lgTXZ8`

    const dispatch = useDispatch();
    

    useEffect(()=>{
        console.log('Google id', googleId);

        if(!googleId?.length && navigate){
            console.log('true')
            navigate('/generator/')
        }
    }, [navigate, googleId])

    useEffect(()=>{
        dispatch(resetWords())
        fetch(`/api/sheet?googleDocId=${googleId}&sheetName=${encodeURIComponent(title)}`).then(async value=>{
            const json = await value.json();
            const {puzzleData} = json;

            if(puzzleData?.words){
                dispatch(setWords(puzzleData?.words))
            }
            if(puzzleData){
                dispatch(setWordSearchConfig(puzzleData))
            }
            if(puzzleData?.hiddenWords){
                dispatch(setHiddenWords(puzzleData?.hiddenWords))
            }
        })
    }, [title])
    
    if(typeof window === `undefined`) return null;
    
    return <Layout>
        <Title order={1}>Sheet {title} </Title>
        <Main />
        </Layout>
}

export default Sheets;